var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('w-top-bar',[_vm._v("Productos")]),_c('div',{staticClass:"h-full p-5 bg-gray-200"},[_c('div',{staticClass:"flex items-center justify-between h-12 px-1"},[_c('div',{staticClass:"flex items-center space-x-2 text-xs"},[_c('w-select',{staticClass:"w-56",attrs:{"label":"Tipo de Producto","empty-label":"Filtrar por Categoría","options":_vm.categories,"no-select":true,"allow-null":true},model:{value:(_vm.productType),callback:function ($$v) {_vm.productType=$$v},expression:"productType"}})],1),_c('router-link',{staticClass:"text-sm text-blue-500 cursor-pointer hover:text-blue-600",attrs:{"to":{ name: 'products-id', params: { id: 'new' } }}},[_vm._v("Nuevo Producto")])],1),_c('div',{staticClass:"w-full mx-auto"},[_c('div',{staticClass:"border-b border-gray-400 dark:border-gray-700 mb-4"},[_c('ul',{staticClass:"flex flex-wrap -mb-px mt-2",attrs:{"id":"myTab","data-tabs-toggle":"#myTabContent","role":"tablist"}},[_c('li',{staticClass:"mr-2",attrs:{"role":"presentation"}},[_c('button',{staticClass:"inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300",class:{
                'border-b border-indigo-700': _vm.tabActive === 'activos',
              },attrs:{"id":"profile-tab","type":"button","role":"tab","aria-controls":"profile","aria-selected":"false"},on:{"click":function($event){return _vm.changeTab('activos')}}},[_vm._v(" Activos ")])]),_c('li',{staticClass:"mr-2",attrs:{"role":"presentation"}},[_c('button',{staticClass:"inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 active",class:{
                'border-b border-indigo-700': _vm.tabActive === 'sin-stock',
              },attrs:{"id":"dashboard-tab","data-tabs-target":"#dashboard","type":"button","role":"tab","aria-controls":"dashboard","aria-selected":"true"},on:{"click":function($event){return _vm.changeTab('sin-stock')}}},[_vm._v(" Sin stock ")])]),_c('li',{staticClass:"mr-2",attrs:{"role":"presentation"}},[_c('button',{staticClass:"inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300",class:{
                'border-b border-indigo-700': _vm.tabActive === 'desactivos',
              },attrs:{"id":"settings-tab","data-tabs-target":"#settings","type":"button","role":"tab","aria-controls":"settings","aria-selected":"false"},on:{"click":function($event){return _vm.changeTab('desactivos')}}},[_vm._v(" Desactivados ")])])])])]),_c('transition',{attrs:{"leave-active-class":"transition-all duration-300 ease-in-out","enter-active-class":"transition-all duration-200 ease-in-out","enter-class":"opacity-0 ","enter-to-class":"opacity-100 ","leave-to-class":"opacity-100 ","leave-class":"opacity-0 "}},[_c('w-products',{class:{
          'opacity-50': _vm.busy,
        },attrs:{"items":_vm.productsList,"hide-order":true},on:{"update":function($event){return _vm.update()}}}),(false)?_c('w-loading'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }