<template>
  <div>
    <w-top-bar>Productos</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div class="flex items-center justify-between h-12 px-1">
        <div class="flex items-center space-x-2 text-xs">
          <w-select
            label="Tipo de Producto"
            empty-label="Filtrar por Categoría"
            class="w-56"
            v-model="productType"
            :options="categories"
            :no-select="true"
            :allow-null="true"
          />
        </div>
        <router-link
          :to="{ name: 'products-id', params: { id: 'new' } }"
          class="text-sm text-blue-500 cursor-pointer hover:text-blue-600"
          >Nuevo Producto</router-link
        >
        <!-- <button @click="fake()" class="text-sm text-blue-500 cursor-pointer hover:text-blue-600">Add Fake Evento</button> -->
      </div>

      <!-- This is an example component -->
      <div class="w-full mx-auto">
        <div class="border-b border-gray-400 dark:border-gray-700 mb-4">
          <ul
            class="flex flex-wrap -mb-px mt-2"
            id="myTab"
            data-tabs-toggle="#myTabContent"
            role="tablist"
          >
            <li class="mr-2" role="presentation">
              <button
                class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300"
                id="profile-tab"
                type="button"
                role="tab"
                @click="changeTab('activos')"
                :class="{
                  'border-b border-indigo-700': tabActive === 'activos',
                }"
                aria-controls="profile"
                aria-selected="false"
              >
                Activos
              </button>
            </li>
            <li class="mr-2" role="presentation">
              <button
                class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300 active"
                id="dashboard-tab"
                data-tabs-target="#dashboard"
                type="button"
                @click="changeTab('sin-stock')"
                :class="{
                  'border-b border-indigo-700': tabActive === 'sin-stock',
                }"
                role="tab"
                aria-controls="dashboard"
                aria-selected="true"
              >
                Sin stock
              </button>
            </li>
            <li class="mr-2" role="presentation">
              <button
                class="inline-block text-gray-500 hover:text-gray-600 hover:border-gray-300 rounded-t-lg py-2 px-4 text-sm font-medium text-center border-transparent border-b-2 dark:text-gray-400 dark:hover:text-gray-300"
                id="settings-tab"
                data-tabs-target="#settings"
                type="button"
                @click="changeTab('desactivos')"
                :class="{
                  'border-b border-indigo-700': tabActive === 'desactivos',
                }"
                role="tab"
                aria-controls="settings"
                aria-selected="false"
              >
                Desactivados
              </button>
            </li>
          </ul>
        </div>
      </div>
      <transition
        leave-active-class="transition-all duration-300 ease-in-out"
        enter-active-class="transition-all duration-200 ease-in-out"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-to-class="opacity-100 "
        leave-class="opacity-0 "
      >
        <w-products
          :class="{
            'opacity-50': busy,
          }"
          :items="productsList"
          @update="update()"
          :hide-order="true"
        />
        <w-loading v-if="false" />
      </transition>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex"; // Step 1
import { mapActions } from "vuex";
import WLoading from "../../components/WLoading.vue";

export default {
  components: { WLoading },
  name: "productsList",
  data() {
    return {
      localProducts: false,
      productType: null,
      tabActive: "activos",
      results: {},
      busy: false,
      products: [],
    };
  },
  mixins: [
    makeFindMixin({ service: "categories" }),
    // makeFindMixin({ service: "products" }),
  ],
  created() {
    this.refresh();
  },
  computed: {
    productTypeKey() {
      return this.productType;
    },
    categoriesParams() {
      return {
        query: {
          $sort: {
            name: -1,
          },
        },
      };
    },
    query() {
      let query = {};
      if (this.productTypeKey) {
        query.types = { $in: [this.productTypeKey] };
      }

      if (this.tabActive === "activos") {
        // query.stock = { $gt: 0 };
        query.isPublic = true;
      }

      if (this.tabActive === "sin-stock") {
        query.$or = [
          {
            stock: { $lt: 1 },
          },
          {
            stock: null,
          },
        ];
      }

      if (this.tabActive === "desactivos") {
        query.isPublic = false;
      }

      console.log(query);

      return {
        query: {
          ...query,
          $sort: {
            sort: 1,
          },
          $limit: 10000,
        },
      };
    },
    productsList() {
      // if (!this.results[this.tabActive]) return this.products;
      return this.products;
    },
  },
  methods: {
    ...mapActions("products", { find: "find" }),
    async refresh() {
      this.busy = true;
      this.products = (await this.find(this.query)).data;
      this.busy = false;
    },
    changeTab(tab) {
      this.tabActive = tab;
      this.refresh();
    },
    update() {
      this.find();
    },
  },
};
</script>

<style lang="scss" scoped></style>
