<template>
  <!-- Contenedor principal del componente -->
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-300">
            <!-- Cabecera de la tabla -->
            <thead>
              <tr>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                >
                  Titulo
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                >
                  Público
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase bg-gray-50"
                >
                  Precio
                </th>
                <th class="px-6 py-3 bg-gray-50"></th>
              </tr>
            </thead>

            <!-- Cuerpo de la tabla (productos ordenables) -->
            <draggable
              v-bind="dragOptions"
              @start="drag = true"
              @end="drag = false"
              v-model="products"
              @change="change"
              tag="tbody"
              class="bg-white divide-y divide-gray-300"
              handle=".handle"
            >
              <!-- Fila para cada producto -->
              <tr
                :class="{ 'opacity-50 hover:opacity-100': past }"
                class="bg-white"
                v-for="(product, productIndex) in products"
                :key="productIndex"
              >
                <!-- Celda con el título, imagen y otros detalles del producto -->
                <td class="whitespace-nowrap">
                  <div class="flex items-center">
                    <!-- Botón para mover el producto -->
                    <svg
                      v-if="!hideOrder && productIndex === 'busy'"
                      class="w-3 h-3 text-gray-500 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-50"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-100"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <button
                      v-if="!hideOrder && productIndex !== editandoSort"
                      v-tooltip="
                        'Click y arrastrar para mover el producto, doble click para editar el orden'
                      "
                      @dblclick="editarSort(productIndex)"
                      class="handle p-1 m-2 border flex align-center rounded text-xs text-left hover:bg-indigo-200 text-gray-700 hover:text-gray-900"
                    >
                      <span class="me-2 text-sm">{{ product.sort + 1 }}</span>
                      <svg
                        class="w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                        />
                      </svg>
                    </button>
                    <div class="min-w-2" v-else>
                      <input
                        v-if="!hideOrder"
                        :value="sortAEditar"
                        @keyup.enter="
                          guardarNuevoOrden(
                            productIndex,
                            $event.target.value - 1
                          )
                        "
                        class="w-10 p-1 m-2 border rounded text-xs text-left hover:bg-indigo-200 text-gray-700 hover:text-gray-900"
                      />
                    </div>

                    <!-- Información del producto (título, imagen, etc.) -->
                    <div
                      class="flex items-center w-56 text-sm font-medium leading-5 text-gray-900 truncate"
                    >
                      <img
                        class="w-10 h-10 pr-2 rounded"
                        :src="product.image"
                      />
                      <div class="">
                        <div>{{ product.name }}</div>
                        <div class="text-xs font-normal">{{ product.sku }}</div>
                        <div
                          v-if="!product.types"
                          class="text-xs italic font-normal text-gray-500"
                        >
                          {{ product.type }}
                        </div>
                        <div
                          v-else
                          class="text-xs italic font-normal text-gray-500"
                        >
                          {{ product.types.join(", ") }}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>

                <!-- Celda para la opción de público -->
                <td class="px-6 py-4 whitespace-nowrap">
                  <w-switch
                    :value="product.isPublic"
                    @input="togglePublic(product, $event)"
                  ></w-switch>
                </td>

                <!-- Celda para los precios del producto -->
                <td class="px-6 py-4 text-sm whitespace-nowrap">
                  <div><w-price :value="product.price.ars" /></div>
                  <div>
                    <w-price currency="USD" :value="product.price.usd" />
                  </div>
                </td>

                <!-- Celda para las acciones del producto (editar, clonar, eliminar) -->
                <td
                  class="py-4 pl-6 text-sm font-medium leading-5 text-right whitespace-nowrap"
                >
                  <div
                    class="relative flex items-center justify-end actions mr-3"
                  >
                    <!-- Botón para editar el producto -->
                    <div
                      class="p-1 py-1 text-xs text-left hover:bg-gray-100 text-gray-700 hover:text-gray-900"
                    >
                      <router-link
                        v-tooltip="'Editar'"
                        class="flex items-center cursor-pointer"
                        :to="{
                          name: 'products-id',
                          params: {
                            id: product._id,
                          },
                        }"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                          />
                        </svg>
                      </router-link>
                    </div>

                    <!-- Botón para clonar el producto -->
                    <div class="p-1 py-1 text-xs text-left hover:bg-gray-100">
                      <a
                        v-tooltip="'Clonar'"
                        class="flex items-center cursor-pointer"
                        @click="clone(product._id)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                          />
                        </svg>
                      </a>
                    </div>

                    <!-- Enlace para ir a la página del producto -->
                    <a
                      v-tooltip="'Página del Producto'"
                      :href="domain + '/' + product.slug"
                      target="_blank"
                      class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-gray-100"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                    </a>

                    <!-- Botón para eliminar el producto -->
                    <div
                      @click="remove(product._id)"
                      v-tooltip="'Eliminar'"
                      class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-red-500 hover:text-white rounded"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </div>

                    <!-- Menú desplegable para acciones -->
                    <v-popover
                      offset="5"
                      placement="auto"
                      :id="product._id"
                      class="inline-block"
                    >
                      <!-- Botón que muestra el menú desplegable -->
                      <span class="rounded-md shadow-sm md:hidden">
                        <button
                          type="button"
                          class="inline-flex justify-center w-full px-2 py-1 text-xs font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800"
                          id="options-menu"
                          aria-haspopup="menu"
                          aria-expanded="true"
                        >
                          Acciones
                          <svg
                            class="w-5 h-5 ml-2 -mr-1"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </span>

                      <!-- Contenido del menú desplegable -->
                      <template slot="popover" class="p-0 m-0">
                        <!-- Opciones del menú -->
                        <div
                          class="p-1 py-1 text-xs text-left hover:bg-gray-100"
                        >
                          <router-link
                            class="flex items-center cursor-pointer"
                            :to="{
                              name: 'products-id',
                              params: {
                                id: product._id,
                              },
                            }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                            Editar producto
                          </router-link>
                        </div>
                        <div
                          class="p-1 py-1 text-xs text-left hover:bg-gray-100"
                        >
                          <a
                            class="flex items-center cursor-pointer"
                            @click="clone(product._id)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                              />
                            </svg>
                            Clonar
                          </a>
                        </div>
                        <div
                          class="p-1 py-1 text-xs text-left hover:bg-gray-100"
                        >
                          <router-link
                            class="flex items-center cursor-pointer"
                            :to="{
                              name: 'sales-id',
                              params: { id: product._id },
                            }"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                            </svg>
                            Ver Ventas
                          </router-link>
                        </div>
                        <a
                          :href="domain + '/' + product.slug"
                          target="_blank"
                          class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-gray-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                            />
                          </svg>
                          Página del Producto
                        </a>

                        <div
                          class="flex items-center p-1 py-1 text-xs text-left border-t border-gray-200 cursor-pointer hover:bg-red-500 hover:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                          Eliminar
                        </div>
                      </template>
                    </v-popover>
                  </div>
                </td>
              </tr>
            </draggable>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Importar el componente de arrastrar y soltar (vuedraggable) y la función mapActions desde Vuex
import draggable from "vuedraggable";
import { mapActions } from "vuex";

export default {
  components: {
    draggable,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    past: {
      type: Boolean,
      default: false,
    },
    hideOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sortAEditar: null,
      editandoSort: -1,
      products: [],
      drag: null,
      domain: "https://tienda.orsai.org",
    };
  },
  mounted() {},
  watch: {
    // Observar los cambios en la propiedad 'items' y actualizar la lista de productos
    items: {
      immediate: true,
      handler(items, old) {
        if (!old || old.length !== items.length) {
          console.log("PRODUCTS CHANGE");
          this.products = items;
        }
      },
    },
  },
  computed: {
    // Opciones para el componente draggable
    dragOptions() {
      return {
        animation: 200,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    // Mapear las acciones de Vuex como métodos locales
    ...mapActions("products", {
      patchProduct: "patch",
      removeProduct: "remove",
      getProduct: "get",
      setProduct: "set",
    }),
    ...mapActions("products-sort", {
      nuevoOrden: "create",
    }),
    async guardarNuevoOrden(indexOrigen, sort) {
      // Si sort es igual al sort del producto, no hacer nada
      // Si sort es menor a 0, no hacer nada
      // Si sort es mayor a la cantidad de productos, no hacer nada
      // Mostrar toast para cada caso

      if (sort === this.products[indexOrigen].sort) {
        this.$toast.warning(`El producto ya tiene el orden ${sort + 1}`);
        this.editandoSort = -1;
        return;
      }

      if (sort < 0) {
        this.$toast.warning(`El orden debe ser mayor a 1`);
        this.editandoSort = -1;
        return;
      }

      if (sort > this.products.length - 1) {
        // Poner ultimo
        sort = this.products.length - 1;
      }

      const indexAReemplazar = this.products.findIndex(
        (product) => product.sort === parseInt(sort)
      );

      let products = this.products;
      // Mover el producto desde indexOrigen a indexAReemplazar, desplazndo indexAReemplazar y los siguientes hacia abajo
      const productoAMover = this.products[indexOrigen];
      products.splice(indexOrigen, 1);
      products.splice(indexAReemplazar, 0, productoAMover);

      // Actualizar el orden de los productos
      products.forEach((product, index) => {
        product.sort = index;
      });

      // Actualizar el orden de los productos en la base de datos
      // const resultado = await this.nuevoOrden({
      //   moved: {
      //     id: productoAMover._id,
      //     sort: indexAReemplazar,
      //   },
      //   // Array de objetos con el id y el nuevo orden de cada producto
      //   nuevosOrdenes: products.map((product, index) => {
      //     return {
      //       id: product._id,
      //       sort: index,
      //     };
      //   }),
      // });

      this.editandoSort = -1;
    },
    editarSort(index) {
      this.editandoSort = index;
      this.sortAEditar = this.products[index].sort + 1;
    },
    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },

    // Método para cambiar el orden de los productos
    async change(event) {
      if (event.moved) {
        const movedProduct = event.moved.element;
        const newIndex = parseInt(event.moved.newIndex);

        // Actualizar el orden del producto movido
        movedProduct.sort = parseInt(newIndex);

        // Llamar a la acción en Vuex para actualizar el orden en la base de datos
        // await this.patchProduct([movedProduct._id, { sort: newIndex }]);
        // Actualizar el orden de todos los productos que quedaron por debajo del producto movido
        const resultado = await this.nuevoOrden({
          moved: {
            id: movedProduct._id,
            sort: newIndex,
          },
          // Array de objetos con el id y el nuevo orden de cada producto
          nuevosOrdenes: this.products
            .slice(newIndex + 1)
            .map((product, index) => {
              return {
                id: product._id,
                sort: parseInt(newIndex) + index,
              };
            }),
        });

        if (event.update) {
          console.log("UPDATE");

          const toMove = this.products.slice(newIndex + 1);
          const moved = toMove.splice(0, 1)[0];
          toMove.splice(event.moved.newIndex - newIndex, 0, moved);
          this.products = [
            ...this.products.slice(0, newIndex),
            ...toMove,
            ...this.products.slice(newIndex + 1),
          ];
        }

        this.$toast.success(
          `Se actualizó el orden de los productos, y afecto a ${resultado.total} productos`
        );
      }
    },

    // Método para clonar un producto
    async clone(id) {
      console.log("CLONE PRODUCT");
      let product = await this.getProduct(id);
      delete product._id;
      delete product.slug;
      product.sort = this.products.length;
      await this.setProduct(product);
    },

    // Método para eliminar un producto
    async remove(id) {
      console.log("REMOVE PRODUCT");
      await this.removeProduct(id);
      this.$toast.warning("Producto eliminado");
    },

    // Método para cambiar la visibilidad pública de un producto
    async togglePublic(product, value) {
      console.log("TOGGLE PUBLIC");
      product.isPublic = value;
      await this.patchProduct([product._id, { isPublic: value }]);
      if (value) {
        this.$toast.success(
          `La visibilidad del producto ${product.name} se cambió a público`
        );
      } else {
        this.$toast.warning(
          `La visibilidad del producto ${product.name} se cambió a privado`
        );
      }
    },
  },
};
</script>

<style>
/* Estilos para el componente */
.actions svg {
  width: 1.2rem;
  height: 1.2rem;
}
.min-w-2 {
  min-width: 1rem;
}
</style>
